import React, { lazy } from 'react'

const SystemAdminListing = lazy(() =>
  import('./SystemAdmin/SystemAdminListing').then(module => ({
    default: module.SystemAdminListing,
  }))
)

const UserListing = lazy(() =>
  import('./SystemAdmin/User/UserListing').then(module => ({
    default: module.UserListing,
  }))
)

const ResendEmailInvitation = lazy(() =>
  import('./SystemAdmin/User/ResendEmailInvitation').then(module => ({
    default: module.ResendEmailInvitation,
  }))
)

const UserDetail = lazy(() =>
  import('./SystemAdmin/User/UserDetail').then(module => ({
    default: module.UserDetail,
  }))
)
const UserAccess = lazy(() =>
  import('./SystemAdmin/User/UserAccess').then(module => ({
    default: module.UserAccess,
  }))
)
const UserForm = lazy(() =>
  import('./SystemAdmin/User/UserForm').then(module => ({
    default: module.UserForm,
  }))
)

const AccessLevelListing = lazy(() =>
  import('./SystemAdmin/AccessLevel/AccessLevelListing').then(module => ({
    default: module.AccessLevelListing,
  }))
)

const AccessLevelForm = lazy(() =>
  import('./SystemAdmin/AccessLevel/AccessLevelForm').then(module => ({
    default: module.AccessLevelForm,
  }))
)

const AccessLevelDetail = lazy(() =>
  import('./SystemAdmin/AccessLevel/AccessLevelDetail').then(module => ({
    default: module.AccessLevelDetail,
  }))
)

const EmployeeAppListing = lazy(() =>
  import('./SystemAdmin/EmployeeAppSecurity/EmployeeAppListing').then(module => ({
    default: module.EmployeeAppListing,
  }))
)

const EmployeeAppView = lazy(() =>
  import('./SystemAdmin/EmployeeAppSecurity/EmployeeAppView').then(module => ({
    default: module.EmployeeAppView,
  }))
)
const EmployeeAppForm= lazy(() =>
  import('./SystemAdmin/EmployeeAppSecurity/EmployeeAppForm').then(module => ({
    default: module.EmployeeAppForm,
  }))
)

const AutoNumberingSetupListing = lazy(() =>
  import('./SystemAdmin/AutoNumberingSetup/AutoNumberingSetupListing').then(
    module => ({
      default: module.AutoNumberingSetupListing,
    })
  )
)

const GLAccountMappingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/GLAccountMapping/GLAccountMappingListing'
  ).then(module => ({
    default: module.GLAccountMappingListing,
  }))
)

const ProjectMasterListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectMaster/ProjectMasterListing'
  ).then(module => ({
    default: module.ProjectMasterListing,
  }))
)

const ProjectExpenseTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseType/ProjectExpenseTypeListing'
  ).then(module => ({
    default: module.ProjectExpenseTypeListing,
  }))
)


const GLAccountMappingForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/GLAccountMapping/GLAccountMappingForm'
  ).then(module => ({
    default: module.GLAccountMappingForm,
  }))
)

const ProjectExpenseGLAccountMappingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/ProjectGLAccountMappingListing'
  ).then(module => ({
    default: module.ProjectGLAccountMappingListing,
  }))
)

const ProjectExpenseGLAccountMappingForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/ProjectGLAccountMappingForm'
  ).then(module => ({
    default: module.ProjectGLAccountMappingForm,
  }))
)

const AutoNumberingSetupForm = lazy(() =>
  import('./SystemAdmin/AutoNumberingSetup/AutoNumberingSetupForm').then(
    module => ({
      default: module.AutoNumberingSetupForm,
    })
  )
)

const CompanyListing = lazy(() =>
  import('./SystemAdmin/Company/CompanyListing').then(module => ({
    default: module.CompanyListing,
  }))
)

const CompanyInfoForm = lazy(() =>
  import('./SystemAdmin/Company/CompanyInfoForm').then(module => ({
    default: module.CompanyInfoForm,
  }))
)

const CompanyAltAddressForm = lazy(() =>
  import('./SystemAdmin/Company/CompanyAltAddressForm').then(module => ({
    default: module.CompanyAltAddressForm,
  }))
)

const CompanySummary = lazy(() =>
  import('./SystemAdmin/Company/CompanySummary').then(module => ({
    default: module.CompanySummary,
  }))
)

const ELetterTemplateListing = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/E-LetterTemplateListing').then(
    module => ({
      default: module.ELetterTemplateListing,
    })
  )
)

const DataChangesApprovalPolicyListing = lazy(() =>
  import(
    './SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyListing'
  ).then(module => ({
    default: module.DataChangesApprovalPolicyListing,
  }))
)
const DataChangesApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyForm'
  ).then(module => ({
    default: module.DataChangesApprovalPolicyForm,
  }))
)
const ReportTemplateView = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportTemplateView').then(
    module => ({
      default: module.ReportTemplateView,
    })
  )
)
const ReportTemplateSetupListing = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportTemplateSetupListing').then(
    module => ({
      default: module.ReportTemplateSetupListing,
    })
  )
)

const StandardELetteringTemplateListing = lazy(() =>
  import(
    './SystemAdmin/E-LetterTemplateSetup/StandardELetteringTemplateListing'
  ).then(module => ({
    default: module.StandardELetteringTemplateListing,
  }))
)

const ReportTemplateForm = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm').then(
    module => ({
      default: module.ReportTemplateForm,
    })
  )
)

const ReportForPreview = lazy(() =>
  import('./SystemAdmin/E-LetterTemplateSetup/ReportForPreview').then(
    module => ({
      default: module.ReportForPreview,
    })
  )
)

const GeneralSettingListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/GeneralSettingListing').then(module => ({
    default: module.GeneralSettingListing,
  }))
)


const GeneralSettingListingV2 = lazy(() =>
  import('./SystemAdmin/GeneralSetting/GeneralSettingListingV2').then(
    module => ({
      default: module.GeneralSettingListingV2,
    })
  )
)

const PersonalManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/PersonalManagement'
  ).then(module => ({
    default: module.PersonalManagement,
  }))
)

const PayrollManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/PayrollManagement'
  ).then(module => ({
    default: module.PayrollManagement,
  }))
)

const LeaveManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/LeaveManagement'
  ).then(module => ({
    default: module.LeaveManagement,
  }))
)

const ClaimManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/ClaimManagement'
  ).then(module => ({
    default: module.ClaimManagement,
  }))
)

const ProjectExpenseManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/ProjectExpenseManagement'
  ).then(module => ({
    default: module.ProjectExpenseManagement,
  }))
)

const TimeAttendanceManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/TimeAttendanceManagement'
  ).then(module => ({
    default: module.TimeAttendanceManagement,
  }))
)

const RequisitionManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/RequisitionManagement'
  ).then(module => ({
    default: module.RequisitionManagement,
  }))
)

const TrainingManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/TrainingManagement'
  ).then(module => ({
    default: module.TrainingManagement,
  }))
)

const AppraisalManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/AppraisalManagement'
  ).then(module => ({
    default: module.AppraisalManagement,
  }))
)

const PerformanceManagement = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CommonSettingRoute/PerformanceManagement'
  ).then(module => ({
    default: module.PerformanceManagement,
  }))
)


const Helpdesk = lazy(() =>
  import('./SystemAdmin/GeneralSetting/CommonSettingRoute/Helpdesk').then(
    module => ({
      default: module.Helpdesk,
    })
  )
)

const HelpdeskSubjectListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectListing'
  ).then(module => ({
    default: module.HelpdeskSubjectListing,
  }))
)

const HelpdeskSubjectForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectForm'
  ).then(module => ({
    default: module.HelpdeskSubjectForm,
  }))
)

const HelpdeskSubjectDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectDetails'
  ).then(module => ({
    default: module.HelpdeskSubjectDetails,
  }))
)


const AttendanceAnalysisTemplate = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AttendanceAnalysisTemplate/AttendanceAnalysisTemplate'
  ).then(module => ({
    default: module.AttendanceAnalysisTemplate,
  }))
)

const RatingScaleListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/RatingScale/RatingScaleListing').then(
    module => ({
      default: module.RatingScaleListing,
    })
  )
)

const RatingScaleForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/RatingScale/RatingScaleForm').then(
    module => ({
      default: module.RatingScaleForm,
    })
  )
)

const FinalRatingTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FinalRatingTable/FinalRatingTableListing'
  ).then(module => ({
    default: module.FinalRatingTableListing,
  }))
)

const FinalRatingTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FinalRatingTable/FinalRatingTableForm'
  ).then(module => ({
    default: module.FinalRatingTableForm,
  }))
)

const AppraisalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalPolicy/AppraisalPolicyForm'
  ).then(module => ({
    default: module.AppraisalPolicyForm,
  }))
)

const AppraisalPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalPolicy/AppraisalPolicyListing'
  ).then(module => ({
    default: module.AppraisalPolicyListing,
  }))
)

const KpiCategoryListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPICategory/KpiCategoryListing').then(
    module => ({
      default: module.KpiCategoryListing,
    })
  )
)

const KpiItemsListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPIItems/KpiItemsListing').then(
    module => ({
      default: module.KpiItemsListing,
    })
  )
)


const KpiItemsDetails = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPIItems/KpiItemsDetails').then(
    module => ({
      default: module.KpiItemsDetails,
    })
  )
)

const KpiItemsForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/KPIItems/KpiItemsForm').then(module => ({
    default: module.KpiItemsForm,
  }))
)

const RewardGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/RewardGroup/RewardGroupListing').then(
    module => ({
      default: module.RewardGroupListing,
    })
  )
)

const KPIPredefinedTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIPredefinedTemplate/KPIPredefinedTemplateListing'
  ).then(module => ({
    default: module.KPIPredefinedTemplateListing,
  }))
)

const KPIPredefinedTemplateForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIPredefinedTemplate/KPIPredefinedTemplateForm'
  ).then(module => ({
    default: module.KPIPredefinedTemplateForm,
  }))
)

const KPIPredefinedTemplateDetailsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIPredefinedTemplate/KPIPredefinedTemplateDetailsForm'
  ).then(module => ({
    default: module.KPIPredefinedTemplateDetailsForm,
  }))
)

const ReviewItemsListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewItems/ReviewItemsListing').then(
    module => ({
      default: module.ReviewItemsListing,
    })
  )
)

const ReviewItemsForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewItems/ReviewItemsForm').then(
    module => ({
      default: module.ReviewItemsForm,
    })
  )
)

const ReviewTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewTemplate/ReviewTemplateListing'
  ).then(module => ({
    default: module.ReviewTemplateListing,
  }))
)

const ReviewTemplateForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewTemplate/ReviewTemplateForm').then(
    module => ({
      default: module.ReviewTemplateForm,
    })
  )
)

const JobSkillCategoryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/JobSkillCategory/JobSkillCategoryListing'
  ).then(module => ({
    default: module.JobSkillCategoryListing,
  }))
)

const CompetencyItemsListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CompetencyItems/CompetencyItemsListing'
  ).then(module => ({
    default: module.CompetencyItemsListing,
  }))
)

const CompetencyItemsDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CompetencyItems/CompetencyItemsDetails'
  ).then(module => ({
    default: module.CompetencyItemsDetails,
  }))
)

const CompetencyItemsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/CompetencyItems/CompetencyItemsForm'
  ).then(module => ({
    default: module.CompetencyItemsForm,
  }))
)

const OtherAppraisalItemsListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OtherAppraisalItems/OtherAppraisalItemsListing'
  ).then(module => ({
    default: module.OtherAppraisalItemsListing,
  }))
)

const OtherAppraisalItemsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OtherAppraisalItems/OtherAppraisalItemsForm'
  ).then(module => ({
    default: module.OtherAppraisalItemsForm,
  }))
)

const KpiAppraisalTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIAppraisalTemplate/KpiAppraisalTemplateListing'
  ).then(module => ({
    default: module.KpiAppraisalTemplateListing,
  }))
)

const KpiAppraisalTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIAppraisalTemplate/KpiAppraisalTemplateDetails'
  ).then(module => ({
    default: module.KpiAppraisalTemplateDetails,
  }))
)

const KpiAppraisalTemplateForms = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/KPIAppraisalTemplate/KpiAppraisalTemplateForms'
  ).then(module => ({
    default: module.KpiAppraisalTemplateForms,
  }))
)

const SkillsAppraisalTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillsAppraisalTemplate/SkillsAppraisalTemplateListing'
  ).then(module => ({
    default: module.SkillsAppraisalTemplateListing,
  }))
)

const SkillsAppraisalTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillsAppraisalTemplate/SkillsAppraisalTemplateDetails'
  ).then(module => ({
    default: module.SkillsAppraisalTemplateDetails,
  }))
)

const SkillsAppraisalTemplateForms = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillsAppraisalTemplate/SkillsAppraisalTemplateForms'
  ).then(module => ({
    default: module.SkillsAppraisalTemplateForms,
  }))
)

const OthersAppraisalTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OthersAppraisalTemplate/OthersAppraisalTemplateListing'
  ).then(module => ({
    default: module.OthersAppraisalTemplateListing,
  }))
)

const OthersAppraisalTemplateDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OthersAppraisalTemplate/OtherAppraisalTemplateDetails'
  ).then(module => ({
    default: module.OthersAppraisalTemplateDetails,
  }))
)


const AppraisalScheduleListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalSchedule/AppraisalScheduleListing'
  ).then(module => ({
    default: module.AppraisalScheduleListing,
  }))
)

const AppraisalScheduleForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalSchedule/AppraisalScheduleForm'
  ).then(module => ({
    default: module.AppraisalScheduleForm,
  }))
)

const AppraisalTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalTemplate/AppraisalTemplateListing'
  ).then(module => ({
    default: module.AppraisalTemplateListing,
  }))
)

const AppraisalTemplateJobPosition = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalTemplate/AppraisalTemplatebyPositionListing'
  ).then(module => ({
    default: module.AppraisalTemplatebyPositionListing,
  }))
)

const AppraisalTemplateJobPositionDetails = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalTemplate/AppraisalTemplatebyPositionDetail'
  ).then(module => ({
    default: module.AppraisalTemplatebyPositionDetail,
  }))
)

const AppraisalApprovalProcessListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalApprovalProcess/AppraisalApprovalProcessListing'
  ).then(module => ({
    default: module.AppraisalApprovalProcessListing,
  }))
)

const AppraisalApprovalProcessForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalApprovalProcess/AppraisalApprovalProcessForm'
  ).then(module => ({
    default: module.AppraisalApprovalProcessForm,
  }))
)

const ScaleRatingListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ScaleRating/ScaleRatingListing').then(
    module => ({
      default: module.ScaleRatingListing,
    })
  )
)

const ScaleRatingForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ScaleRating/ScaleRatingForm').then(
    module => ({
      default: module.ScaleRatingForm,
    })
  )
)

const AppraisalApprovalPolicyList = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalApprovalPolicy/AppraisalApprovalPolicy'
  ).then(module => ({
    default: module.AppraisalApprovalPolicy,
  }))
)

const AppraisalApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AppraisalApprovalPolicy/AppraisalApprovalPolicyForm'
  ).then(module => ({
    default: module.AppraisalApprovalPolicyForm,
  }))
)

const TrainingCourseClassificationListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//TrainingCourseClassification/TrainingCourseClassificationListing'
  ).then(module => ({
    default: module.TrainingCourseClassificationListing,
  }))
)

const TrainingCoursesListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//TrainingCourses/TrainingCoursesListing'
  ).then(module => ({
    default: module.TrainingCoursesListing,
  }))
)

const AllCoursesListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//TrainingCourses/AllCoursesListing'
  ).then(module => ({
    default: module.AllCoursesListing,
  }))
)

const AuthorisedTrainingProviders = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//AuthorisedTrainingProviders/AuthorisedTrainingProvidersListing'
  ).then(module => ({
    default: module.AuthorisedTrainingProvidersListing,
  }))
)


const AuthorisedTrainingProvidersForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//AuthorisedTrainingProviders/AuthorisedTrainingProvidersForm'
  ).then(module => ({
    default: module.AuthorisedTrainingProvidersForm,
  }))
)

const CoursesListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//AuthorisedTrainingProviders/CoursesListing'
  ).then(module => ({
    default: module.CoursesListing,
  }))
)

const EditTrainingCourse = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//TrainingCourses/EditTrainingCourse'
  ).then(module => ({
    default: module.EditTrainingCourse,
  }))
)

const PreferredTrainer = lazy(() =>
  import('./SystemAdmin/GeneralSetting//TrainingCourses/PreferredTrainer').then(
    module => ({
      default: module.PreferredTrainer,
    })
  )
)

const JobGradeAllowed = lazy(() =>
  import('./SystemAdmin/GeneralSetting//TrainingCourses/JobGradeAllowed').then(
    module => ({
      default: module.JobGradeAllowed,
    })
  )
)

const TrainingCostItemListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//TrainingCostItem/TrainingCostItemListing'
  ).then(module => ({
    default: module.TrainingCostItemListing,
  }))
)

const TrainingApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting//TrainingApprovalPolicy/TrainingApprovalPolicyForm'
  ).then(module => ({
    default: module.TrainingApprovalPolicyForm,
  }))
)

const RequisitionApprovalPolicyList = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionApprovalPolicy'
  ).then(module => ({
    default: module.RequisitionApprovalPolicy,
  }))
)

const RequisitionApprovalPolicyDetail = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionApprovalPolicyDetail'
  ).then(module => ({
    default: module.RequisitionApprovalPolicyDetail,
  }))
)

const RequisitionApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionApprovalPolicyForm'
  ).then(module => ({
    default: module.RequisitionApprovalPolicyForm,
  }))
)

const CommonSettingRequisitionAPMenu = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionApprovalPolicy/RequisitionAPMenu'
  ).then(module => ({
    default: module.RequisitionAPMenu,
  }))
)
const CommonSettingRequisitionTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RequisitionType/RequisitionTypeListing'
  ).then(module => ({
    default: module.RequisitioniTypeListing,
  }))
)

const PersonnelParameterListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/PersonnelParameterListing'
  ).then(module => ({
    default: module.PersonnelParameterListing,
  }))
)

const SalutationListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/SalutationListing'
  ).then(module => ({
    default: module.SalutationListing,
  }))
)

const VaccinationTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/VaccinationTypeListing'
  ).then(module => ({
    default: module.VaccinationTypeListing,
  }))
)

const ExitCompanyReasonListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/ExitCompanyReasonListing'
  ).then(module => ({
    default: module.ExitCompanyReasonListing,
  }))
)

const EducationLevelListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/EducationLevelListing'
  ).then(module => ({
    default: module.EducationLevelListing,
  }))
)

const MajorListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PersonnelParameter/MajorListing').then(
    module => ({
      default: module.MajorListing,
    })
  )
)


const GradeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PersonnelParameter/GradeListing').then(
    module => ({
      default: module.GradeListing,
    })
  )
)

const StaffCategoryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/StaffCategoryListing'
  ).then(module => ({
    default: module.StaffCategoryListing,
  }))
)

const SpecializationListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/SpecializationListing'
  ).then(module => ({
    default: module.SpecializationListing,
  }))
)

const IndustryListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/IndustryListing'
  ).then(module => ({
    default: module.IndustryListing,
  }))
)

const LanguageListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/LanguageListing'
  ).then(module => ({
    default: module.LanguageListing,
  }))
)

const DocumentTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelParameter/DocumentTypeListing'
  ).then(module => ({
    default: module.DocumentTypeListing,
  }))
)

const JobGradeForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/JobGrade/JobGradeForm').then(module => ({
    default: module.JobGradeForm,
  }))
)

const SkillSetSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupListing'
  ).then(module => ({
    default: module.SkillSetSetupListing,
  }))
)

const SkillSetSetupForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupForm').then(
    module => ({
      default: module.SkillSetSetupForm,
    })
  )
)


const JobPositionListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/JobPosition/JobPositionListing').then(
    module => ({
      default: module.JobPositionListing,
    })
  )
)

const JobPositionForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/JobPosition/JobPositionForm').then(
    module => ({
      default: module.JobPositionForm,
    })
  )
)

const InsuranceSchemeSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/InsuranceSchemeSetup/InsuranceSchemeSetupListing'
  ).then(module => ({
    default: module.InsuranceSchemeSetupListing,
  }))
)

const RecruitmentSourceListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/RecruitmentSource/RecruitmentSourceListing'
  ).then(module => ({
    default: module.RecruitmentSourceListing,
  }))
)

const UserDefineFieldListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/UserDefineField/UserDefineFieldListing'
  ).then(module => ({
    default: module.UserDefineFieldListing,
  }))
)

const PersonnelEFileSecurityPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PersonnelEFileSecurityPolicy/PersonnelEFileSecurityPolicyForm'
  ).then(module => ({
    default: module.PersonnelEFileSecurityPolicyForm,
  }))
)

const PayItemListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayItem/PayItemListing').then(
    module => ({
      default: module.PayItemListing,
    })
  )
)

const EnhancedPayrollItemForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayItem/EnhancedPayItemForm').then(
    module => ({
      default: module.EnhancedPayrollItemForm,
    })
  )
)

const PayrollItemForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayItem/PayItemForm').then(module => ({
    default: module.PayrollItemForm,
  }))
)

const AdvancePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyListing'
  ).then(module => ({
    default: module.AdvancePolicyListing,
  }))
)

const AdvancePolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyForm').then(
    module => ({
      default: module.AdvancePolicyForm,
    })
  )
)


const LHDNReportListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LHDNReport/LHDNReportListing').then(
    module => ({
      default: module.LHDNReportListing,
    })
  )
)

const LHDNReportForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LHDNReport/LHDNReportForm').then(
    module => ({
      default: module.LHDNReportForm,
    })
  )
)

const PayrollGLAccountMappingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollGLAccountMapping/GLAccountMappingListing'
  ).then(module => ({
    default: module.GLAccountMappingListing,
  }))
)

const PayrollGLAccountMappingForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollGLAccountMapping/GLAccountMappingForm'
  ).then(module => ({
    default: module.GLAccountMappingForm,
  }))
)

const UserDefineEPFPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollUserDefineEPFPolicy/UserDefineEPFPolicyListing'
  ).then(module => ({
    default: module.UserDefineEPFPolicyListing,
  }))
)

const UserDefineEPFPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PayrollUserDefineEPFPolicy/UserDefineEPFPolicyForm'
  ).then(module => ({
    default: module.UserDefineEPFPolicyForm,
  }))
)

const PayGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayGroup/PayGroupListing').then(
    module => ({
      default: module.PayGroupListing,
    })
  )
)

const PayrollCycleListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleListing').then(
    module => ({
      default: module.PayrollCycleListing,
    })
  )
)

const PayrollCycleForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleForm').then(
    module => ({
      default: module.PayrollCycleForm,
    })
  )
)

const LeaveReasonListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveReason/LeaveReasonListing').then(
    module => ({
      default: module.LeaveReasonListing,
    })
  )
)

const DiseaseCodeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/DiseaseCode/DiseaseCodeListing').then(
    module => ({
      default: module.DiseaseCodeListing,
    })
  )
)

const LeaveTypeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveType/LeaveTypeListing').then(
    module => ({
      default: module.LeaveTypeListing,
    })
  )
)

const LeavePolicyListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyListing').then(
    module => ({
      default: module.LeavePolicyListing,
    })
  )
)

const LeavePolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyForm').then(
    module => ({
      default: module.LeavePolicyForm,
    })
  )
)

const LeavePolicyDetail = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyDetail').then(
    module => ({
      default: module.LeavePolicyDetail,
    })
  )
)

const LeaveRoundingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingListing'
  ).then(module => ({
    default: module.LeaveRoundingListing,
  }))
)

const LeaveRoundingForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingForm').then(
    module => ({
      default: module.LeaveRoundingForm,
    })
  )
)

const LeaveRoundingDetail = lazy(() =>
  import('./SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingDetail').then(
    module => ({
      default: module.LeaveRoundingDetail,
    })
  )
)

const LeaveApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicy'
  ).then(module => ({
    default: module.LeaveApprovalPolicy,
  }))
)

const LeaveApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicyForm'
  ).then(module => ({
    default: module.LeaveApprovalPolicyForm,
  }))
)

const ClaimParameterListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/ClaimParameterListing'
  ).then(module => ({
    default: module.ClaimParameterListing,
  }))
)

const VehicleTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/VehicleType/VehicleTypeListing'
  ).then(module => ({
    default: module.VehicleTypeListing,
  }))
)
const ClaimUserDefineFieldsListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsListing'
  ).then(module => ({
    default: module.ClaimUserDefineFieldsListing,
  }))
)
const ClaimUserDefineFieldsForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsForm'
  ).then(module => ({
    default: module.ClaimUserDefineFieldsForm,
  }))
)

const MileageLocationListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MileageLocation/MileageLocationListing'
  ).then(module => ({
    default: module.MileageLocationListing,
  }))
)

const MileageTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableListing'
  ).then(module => ({
    default: module.MileageTableListing,
  }))
)

const MileageTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableForm'
  ).then(module => ({
    default: module.MileageTableForm,
  }))
)

const MealTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/MealType/MealTypeListing'
  ).then(module => ({
    default: module.MealTypeListing,
  }))
)

const VenueListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimParameters/Venue/VenueListing'
  ).then(module => ({
    default: module.VenueListing,
  }))
)


const ClaimTypeListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimType/ClaimTypeListing').then(
    module => ({
      default: module.ClaimTypeListing,
    })
  )
)

const ClaimPolicyListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyListing').then(
    module => ({
      default: module.ClaimPolicyListing,
    })
  )
)

const ClaimPolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyForm').then(
    module => ({
      default: module.ClaimPolicyForm,
    })
  )
)

const ClaimPolicyDetail = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDetail').then(
    module => ({
      default: module.ClaimPolicyDetail,
    })
  )
)

const ClaimPolicyDailyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDailyForm').then(
    module => ({
      default: module.ClaimPolicyDailyForm,
    })
  )
)

const ClaimPolicyMealForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyMealForm').then(
    module => ({
      default: module.ClaimPolicyMealForm,
    })
  )
)

const ClaimApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicy'
  ).then(module => ({
    default: module.ClaimApprovalPolicy,
  }))
)

const ClaimApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicyForm'
  ).then(module => ({
    default: module.ClaimApprovalPolicyForm,
  }))
)

const ShiftSetupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupListing').then(
    module => ({
      default: module.ShiftSetupListing,
    })
  )
)

const ShiftSetupForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupForm').then(
    module => ({
      default: module.ShiftSetupForm,
    })
  )
)


const ShiftPatternListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternListing').then(
    module => ({
      default: module.ShiftPatternListing,
    })
  )
)

const ShiftPatternForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternForm').then(
    module => ({
      default: module.ShiftPatternForm,
    })
  )
)

const ShiftGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupListing').then(
    module => ({
      default: module.ShiftGroupListing,
    })
  )
)

const ShiftGroupForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupForm').then(
    module => ({
      default: module.ShiftGroupForm,
    })
  )
)

const OvertimePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyListing'
  ).then(module => ({
    default: module.OvertimePolicyListing,
  }))
)


const OvertimePolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyForm').then(
    module => ({
      default: module.OvertimePolicyForm,
    })
  )
)

const OvertimeClaimTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableListing'
  ).then(module => ({
    default: module.OvertimeClaimTableListing,
  }))
)

const OvertimeClaimTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableForm'
  ).then(module => ({
    default: module.OvertimeClaimTableForm,
  }))
)

const OvertimeClaimTableView = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableView'
  ).then(module => ({
    default: module.OvertimeClaimTableView,
  }))
)

const OvertimeReasonListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeReasonCodes/OvertimeReasonListing'
  ).then(module => ({
    default: module.OvertimeReasonListing,
  }))
)

const OvertimeRoundingListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingListing'
  ).then(module => ({
    default: module.OvertimeRoundingListing,
  }))
)

const OvertimeRoundingPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingForm'
  ).then(module => ({
    default: module.OvertimeRoundingPolicyForm,
  }))
)

const AllowanceDeductionRoundingPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyListing'
  ).then(module => ({
    default: module.AllowanceDeductionRoundingPolicy,
  }))
)

const AllowanceDeductionRoundingPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm'
  ).then(module => ({
    default: module.AllowanceDeductionRoundingPolicyForm,
  }))
)

const DailyAllowancePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyListing'
  ).then(module => ({
    default: module.DailyAllowancePolicyListing,
  }))
)

const DailyAllowancePolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyForm'
  ).then(module => ({
    default: module.DailyAllowancePolicyForm,
  }))
)

const MonthlyAllowancePolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyListing'
  ).then(module => ({
    default: module.MonthlyAllowancePolicyListing,
  }))
)

const MonthlyAllowancePolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyForm'
  ).then(module => ({
    default: module.MonthlyAllowancePolicyForm,
  }))
)

const DeductionPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyListing'
  ).then(module => ({
    default: module.DeductionPolicyListing,
  }))
)

const DeductionPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm'
  ).then(module => ({
    default: module.DeductionPolicyForm,
  }))
)

const TimeOffPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyListing'
  ).then(module => ({
    default: module.TimeOffPolicyListing,
  }))
)

const TimeOffPolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyForm').then(
    module => ({
      default: module.TimeOffPolicyForm,
    })
  )
)

const TimeOffReasonListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TimeOffReasonCode/TimeOffReasonListing'
  ).then(module => ({
    default: module.TimeOffReasonListing,
  }))
)

const MobileCheckInListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInListing'
  ).then(module => ({
    default: module.MobileCheckInListing,
  }))
)

const MobileCheckInForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm'
  ).then(module => ({
    default: module.MobileCheckInForm,
  }))
)

const GenerateQR = lazy(() =>
  import('./SystemAdmin/GeneralSetting/MobileCheckInLocation/GenerateQR').then(
    module => ({
      default: module.GenerateQR,
    })
  )
)

const BeaconDeviceListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceListing'
  ).then(module => ({
    default: module.BeaconDeviceListing,
  }))
)

const BeaconDeviceForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceForm'
  ).then(module => ({
    default: module.BeaconDeviceForm,
  }))
)

const TMSAPMenu = lazy(() =>
  import('./SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSAPMenu').then(
    module => ({
      default: module.TMSAPMenu,
    })
  )
)

const TMSApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy'
  ).then(module => ({
    default: module.TMSApprovalPolicy,
  }))
)

const TMSApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm'
  ).then(module => ({
    default: module.TMSApprovalPolicyForm,
  }))
)

const SystemAdminCompanyInfoForm = lazy(() =>
  import('./SystemAdmin/Company/CompanyInfo/CompanyInfoForm').then(module => ({
    default: module.CompanyInfoForm,
  }))
)

const CompanyInfoView = lazy(() =>
  import('./SystemAdmin/Company/CompanyInfo/CompanyInfoView').then(module => ({
    default: module.CompanyInfoView,
  }))
)
const EmployeeAppSecurityListing = lazy(() =>
  import('./SystemAdmin/Company/EmployeeAppSecAssignment/EmployeeAppSecurityListing').then(module => ({
    default: module.EmployeeAppSecurityListing,
  }))
)

const EmployeeAppSecurityForm = lazy(() =>
  import('./SystemAdmin/Company/EmployeeAppSecAssignment/EmployeeAppSecurityForm').then(module => ({
    default: module.EmployeeAppSecurityForm,
  }))
)

const DepartmentListing = lazy(() =>
  import('./SystemAdmin/Company/Department/DepartmentListing').then(module => ({
    default: module.DepartmentListing,
  }))
)

const DivisionListing = lazy(() =>
  import('./SystemAdmin/Company/Department/DivisionListing').then(module => ({
    default: module.DivisionListing,
  }))
)

const ReportingStructureGroup = lazy(() =>
  import(
    './SystemAdmin/Company/ReportingStructure/ReportingStructureGroup'
  ).then(module => ({
    default: module.ReportingStructureGroup,
  }))
)

const ReportingStructure = lazy(() =>
  import('./SystemAdmin/Company/ReportingStructure/ReportingStructure').then(
    module => ({
      default: module.ReportingStructure,
    })
  )
)

const JobPositionListng = lazy(() =>
  import('./SystemAdmin/Company/ReportingStructure/JobPositionListing').then(
    module => ({
      default: module.JobPositionListng,
    })
  )
)

const StatutoryInfo = lazy(() =>
  import('./SystemAdmin/Company/StatutoryInfo/StatutoryInfo').then(module => ({
    default: module.StatutoryInfo,
  }))
)

const CompanyGeneralDocument = lazy(() =>
  import('./SystemAdmin/Company/GeneralDocument/GeneralDocument').then(
    module => ({
      default: module.GeneralDocument,
    })
  )
)

const CompanyEmployeeAttachment = lazy(() =>
  import('./SystemAdmin/Company/GeneralDocument/EmployeeAttachment').then(
    module => ({
      default: module.EmployeeAttachment,
    })
  )
)

const RoleListing = lazy(() =>
  import('./SystemAdmin/Company/RoleAssignment/RoleListing').then(module => ({
    default: module.RoleListing,
  }))
)

const RoleAssignmentListing = lazy(() =>
  import('./SystemAdmin/Company/RoleAssignment/RoleAssignmentListing').then(
    module => ({
      default: module.RoleAssignmentListing,
    })
  )
)


const CompanyHierarchy = lazy(() =>
  import('./SystemAdmin/Company/CompanyHierarchy/CompanyHierarchy').then(
    module => ({
      default: module.CompanyHierarchy,
    })
  )
)

const OrgUnitStructure = lazy(() =>
  import('./SystemAdmin/Company/OrgUnitStructure/OrgUnitStructure').then(
    module => ({
      default: module.OrgUnitStructure,
    })
  )
)
const OrgUnitStructureForm = lazy(() =>
  import('./SystemAdmin/Company/OrgUnitStructure/OrgUnitStructureForm').then(
    module => ({
      default: module.OrgUnitStructureForm,
    })
  )
)

const JobPostionByOrgUnit = lazy(() =>
  import(
    './SystemAdmin/Company/JobPositionByOrgUnit/JobPositionByOrgUnit'
  ).then(module => ({
    default: module.JobPositionByOrgUnit,
  }))
)

const AccessPermissionRoleListing = lazy(() =>
  import(
    './SystemAdmin/Company/AccessPermission/AccessPermissionRoleListing'
  ).then(module => ({
    default: module.AccessPermissionRoleListing,
  }))
)

const AutoNumberingAssignment = lazy(() =>
  import(
    './SystemAdmin/Company/AutoNumberingAssignment/AutoNumberingAssignment'
  ).then(module => ({
    default: module.AutoNumberingAssignment,
  }))
)

const AutoNumberingAssignmentForm = lazy(() =>
  import(
    './SystemAdmin/Company/AutoNumberingAssignment/AutoNumberingAssignmentForm'
  ).then(module => ({
    default: module.AutoNumberingAssignmentForm,
  }))
)

const AccessPermission = lazy(() =>
  import('./SystemAdmin/Company/AccessPermission/AccessPermission').then(
    module => ({
      default: module.AccessPermission,
    })
  )
)

const DigitalBulletinListing = lazy(() =>
  import('./SystemAdmin/E-Bulletin/DigitalBulletinListing').then(module => ({
    default: module.DigitalBulletinListing,
  }))
)

const DigitalBulletinForm = lazy(() =>
  import('./SystemAdmin/E-Bulletin/DigitalBulletinForm').then(module => ({
    default: module.DigitalBulletinForm,
  }))
)

const AcknowledgedList = lazy(() =>
  import('./SystemAdmin/E-Bulletin/AcknowledgedList').then(module => ({
    default: module.AcknowledgedList,
  }))
)

const ProjectExpenseClaimApprovalPolicy = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicy'
  ).then(module => ({
    default: module.ClaimApprovalPolicy,
  }))
)

const ProjectExpenseClaimApprovalPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicyForm'
  ).then(module => ({
    default: module.ClaimApprovalPolicyForm,
  }))
)

const ImportDataSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupListing'
  ).then(module => ({
    default: module.ImportDataSetupListing,
  }))
)

const ImportDataSetupForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupForm'
  ).then(module => ({
    default: module.ImportDataSetupForm,
  }))
)

const PeriodicDeductionPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyListing'
  ).then(module => ({
    default: module.PeriodicDeductionPolicyListing,
  }))
)

const PeriodicDeductionPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyForm'
  ).then(module => ({
    default: module.PeriodicDeductionPolicyForm,
  }))
)

const FormItemRatingSetupListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FormItemRatingSetup/FormItemRatingSetupListing'
  ).then(module => ({
    default: module.FormItemRatingSetupListing,
  }))
)

const FormItemRatingSetupForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/FormItemRatingSetup/FormItemRatingSetupForm'
  ).then(module => ({
    default: module.FormItemRatingSetupForm,
  }))
)

const ReviewGradingTableListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewGradingTable/ReviewGradingTableListing'
  ).then(module => ({
    default: module.ReviewGradingTableListing,
  }))
)

const ReviewGradingTableForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewGradingTable/ReviewGradingTableForm'
  ).then(module => ({
    default: module.ReviewGradingTableForm,
  }))
)

const ReviewPolicyListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewPolicy/ReviewPolicyListing').then(
    module => ({
      default: module.ReviewPolicyListing,
    })
  )
)

const ReviewPolicyForm = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewPolicy/ReviewPolicyForm').then(
    module => ({
      default: module.ReviewPolicyForm,
    })
  )
)

const ReviewGroupListing = lazy(() =>
  import('./SystemAdmin/GeneralSetting/ReviewGroup/ReviewGroupListing').then(
    module => ({
      default: module.ReviewGroupListing,
    })
  )
)

const ReviewProcessPolicyListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewProcessPolicy/ReviewProcessPolicyListing'
  ).then(module => ({
    default: module.ReviewProcessPolicyListing,
  }))
)

const ReviewProcessPolicyForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewProcessPolicy/ReviewProcessPolicyForm'
  ).then(module => ({
    default: module.ReviewProcessPolicyForm,
  }))
)

const ReviewFormTemplateListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewFormTemplate/ReviewFormTemplateListing'
  ).then(module => ({
    default: module.ReviewFormTemplateListing,
  }))
)

const ReviewFormTemplateForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ReviewFormTemplate/ReviewFormTemplateForm'
  ).then(module => ({
    default: module.ReviewFormTemplateForm,
  }))
)

const Timesheet = lazy(() =>
  import('./SystemAdmin/GeneralSetting/CommonSettingRoute/Timesheet').then(
    module => ({
      default: module.Timesheet,
    })
  )
)

const ApproverRecordListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectTimesheetApproverMaster/ApproverRecordListing'
  ).then(module => ({
    default: module.ApproverRecordListing,
  }))
)

const ApproverAssignmentListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectTimesheetApproverMaster/ApproverAssignmentListing'
  ).then(module => ({
    default: module.ApproverAssignmentListing,
  }))
)

const ApproverAssignmentForm = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/ProjectTimesheetApproverMaster/ApproverAssignmentForm'
  ).then(module => ({
    default: module.ApproverAssignmentForm,
  }))
)

const ReportingStructureGroupListing = lazy(() =>
  import(
    './SystemAdmin/ReportingStructureGroup/ReportingStructureGroupListing'
  ).then(module => ({
    default: module.ReportingStructureGroupListing,
  }))
)

const MulticurrencySetupMenu = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MulticurrencySetupMenu').then(
    module => ({
      default: module.MulticurrencySetupMenu,
    })
  )
)

const MulticurrencyListing = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MulticurrencyListing').then(
    module => ({
      default: module.MulticurrencyListing,
    })
  )
)

const MulticurrencyForm = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MulticurrencyForm').then(
    module => ({
      default: module.MulticurrencyForm,
    })
  )
)

const MultiCurrencyImport = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MultiCurrencyImport').then(
    module => ({
      default: module.MultiCurrencyImport,
    })
  )
)

const MultiCurrencyExcelValidation = lazy(() =>
  import('./SystemAdmin/Multi-currencySetup/MultiCurrencyExcelValidation').then(
    module => ({
      default: module.MultiCurrencyExcelValidation,
    })
  )
)

const PeriodicAllowanceTypeListing = lazy(() =>
  import(
    './SystemAdmin/GeneralSetting/TMSPeriodicAllowanceType/PeriodicAllowanceType/PeriodicAllowanceTypeListing'
  ).then(module => ({
    default: module.PeriodicAllowanceTypeListing,
  }))
)


const HomeRoutes = [
  {
    props: { exact: true, path: '/' },
    component: <SystemAdminListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/SystemAdminListing' },
    component: <SystemAdminListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing' },
    component: <UserListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/ResendEmailInvitation' },
    component: <ResendEmailInvitation />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/detail' },
    component: <UserDetail />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/assign' },
    component: <UserAccess />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/form/add' },
    component: <UserForm formMode="add" />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/User/UserListing/form/edit' },
    component: <UserForm formMode="edit" />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/AccessLevel/AccessLevelListing' },
    component: <AccessLevelListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/Company/CompanyListing' },
    component: <CompanyListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/Company/CompanyInfoForm' },
    component: <CompanyInfoForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanyInfoForm/CompanyAltAddressForm',
    },
    component: <CompanyAltAddressForm />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/Company/CompanySummary' },
    component: <CompanySummary />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AutoNumberingSetup/AutoNumberingSetupListing',
    },
    component: <AutoNumberingSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AutoNumberingSetup/AutoNumberingSetupForm',
    },
    component: <AutoNumberingSetupForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-Bulletin/DigitalBulletinListing',
    },
    component: <DigitalBulletinListing />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/E-Bulletin/DigitalBulletinForm' },
    component: <DigitalBulletinForm />,
  },
  {
    props: { exact: true, path: '/SystemAdmin/E-Bulletin/AcknowledgedList' },
    component: <AcknowledgedList />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/E-LetterTemplateListing',
    },
    component: <ELetterTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyListing',
    },
    component: <DataChangesApprovalPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyForm/Add',
    },
    component: <DataChangesApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/DataChangesApprovalPolicy/DataChangesApprovalPolicyForm/Edit',
    },
    component: <DataChangesApprovalPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateView/:Category',
    },
    component: <ReportTemplateView />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateSetupListing/:Category',
    },
    component: <ReportTemplateSetupListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/StandardELetteringTemplateListing/:Category',
    },
    component: <StandardELetteringTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm/:Category',
    },
    component: <ReportTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm/:Category/new',
    },
    component: <ReportTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/E-LetterTemplateSetup/ReportTemplateForm/:Category/edit',
    },
    component: <ReportTemplateForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/E-LetterTemplateSetup/ReportForPreview/:Category',
    },
    component: <ReportForPreview />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppListing',
    },
    component: <EmployeeAppListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppView',
    },
    component: <EmployeeAppView />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppForm/Add',
    },
    component: <EmployeeAppForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/EmployeeAppSecurity/EmployeeAppForm/Edit/:id',
    },
    component: <EmployeeAppForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Add',
    },
    component: <AccessLevelForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Detail/:id',
    },
    component: <AccessLevelDetail />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Edit/:id',
    },
    component: <AccessLevelForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/AccessLevel/AccessLevelForm/Edit/:id',
    },
    component: <AccessLevelForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListing',
    },
    component: <GeneralSettingListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListingV2',
    },
    component: <GeneralSettingListingV2 />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/PersonalManagement',
    },
    component: <PersonalManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/PayrollManagement',
    },
    component: <PayrollManagement />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/LeaveManagement',
    },
    component: <LeaveManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/ClaimManagement',
    },
    component: <ClaimManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/ProjectExpenseManagement',
    },
    component: <ProjectExpenseManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TimeAttendanceManagement',
    },
    component: <TimeAttendanceManagement />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement',
    },
    component: <RequisitionManagement />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/Helpdesk',
    },
    component: <Helpdesk />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/Timesheet',
    },
    component: <Timesheet />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectListing',
    },
    component: <HelpdeskSubjectListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectForm/Add',
    },
    component: <HelpdeskSubjectForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectForm/Edit',
    },
    component: <HelpdeskSubjectForm mode="Edit" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/HelpdeskSubject/HelpdeskSubjectDetails',
    },
    component: <HelpdeskSubjectDetails />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/AttendanceAnalysisTemplate',
    },
    component: <AttendanceAnalysisTemplate />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement',
    },
    component: <TrainingManagement />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement',
    },
    component: <AppraisalManagement />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/PerformanceManagement',
    },
    component: <PerformanceManagement />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/PersonnelParameterListing',
    },
    component: <PersonnelParameterListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/SalutationListing',
    },
    component: <SalutationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/VaccinationTypeListing',
    },
    component: <VaccinationTypeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/ExitCompanyReasonListing',
    },
    component: <ExitCompanyReasonListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/EducationLevelListing',
    },
    component: <EducationLevelListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/MajorListing',
    },
    component: <MajorListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/GradeListing',
    },
    component: <GradeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/StaffCategoryListing',
    },
    component: <StaffCategoryListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/SpecializationListing',
    },
    component: <SpecializationListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/IndustryListing',
    },
    component: <IndustryListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PersonnelParameter/LanguageListing',
    },
    component: <LanguageListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelParameter/DocumentTypeListing',
    },
    component: <DocumentTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobGrade/JobGradeForm',
    },
    component: <JobGradeForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupListing',
    },
    component: <SkillSetSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupForm/Add',
    },
    component: <SkillSetSetupForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/SkillSetSetup/SkillSetSetupForm/:skillSetID/Edit',
    },
    component: <SkillSetSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobPosition/JobPositionListing',
    },
    component: <JobPositionListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobPosition/JobPositionForm/Add',
    },
    component: <JobPositionForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/JobPosition/JobPositionForm/Edit',
    },
    component: <JobPositionForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/InsuranceSchemeSetup/InsuranceSchemeSetupListing',
    },
    component: <InsuranceSchemeSetupListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/RecruitmentSource/RecruitmentSourceListing',
    },
    component: <RecruitmentSourceListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/UserDefineField/UserDefineFieldListing',
    },
    component: <UserDefineFieldListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayItem/PayItemListing',
    },
    component: <PayItemListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayItem/PayItemForm/:PayItemID',
    },
    // component: <PayrollItemForm />,
    component: <EnhancedPayrollItemForm />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyListing',
    },
    component: <AdvancePolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/AdvancePolicy/AdvancePolicyForm',
    },
    component: <AdvancePolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LHDNReport/LHDNReportListing',
    },
    component: <LHDNReportListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LHDNReport/LHDNReportForm',
    },
    component: <LHDNReportForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GLAccountMapping/listing',
    },
    component: <PayrollGLAccountMappingListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/GLAccountMapping/listing/form',
    },
    component: <PayrollGLAccountMappingForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/UserDefineEPFPolicy/UserDefineEPFPolicyListing',
    },
    component: <UserDefineEPFPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/UserDefineEPFPolicy/UserDefineEPFPolicyForm',
    },
    component: <UserDefineEPFPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayGroup/PayGroupListing',
    },
    component: <PayGroupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleListing',
    },
    component: <PayrollCycleListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/PayrollCycle/PayrollCycleForm',
    },
    component: <PayrollCycleForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveReason/LeaveReasonListing',
    },
    component: <LeaveReasonListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/DiseaseCode/DiseaseCodeListing',
    },
    component: <DiseaseCodeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveType/LeaveTypeListing',
    },
    component: <LeaveTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyListing',
    },
    component: <LeavePolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyForm/add',
    },
    component: <LeavePolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyForm/:leavePolicyID/edit',
    },
    component: <LeavePolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeavePolicy/LeavePolicyDetail',
    },
    component: <LeavePolicyDetail />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingListing',
    },
    component: <LeaveRoundingListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingForm/add',
    },
    component: <LeaveRoundingForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingForm/:leaveRoundingID/edit',
    },
    component: <LeaveRoundingForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/LeaveRounding/LeaveRoundingDetail',
    },
    component: <LeaveRoundingDetail />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicy',
    },
    component: <LeaveApprovalPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicyForm/add',
    },
    component: <LeaveApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/LeaveApprovalPolicy/LeaveApprovalPolicyForm/edit',
    },
    component: <LeaveApprovalPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimParameters/ClaimParameterListing',
    },
    component: <ClaimParameterListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/VehicleType/VehicleTypeListing',
    },
    component: <VehicleTypeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsListing',
    },
    component: <ClaimUserDefineFieldsListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/ClaimUserDefineFields/ClaimUserDefineFieldsListing/ClaimUserDeFineFieldsForm',
    },
    component: <ClaimUserDefineFieldsForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageLocation/MileageLocationListing',
    },
    component: <MileageLocationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableListing',
    },
    component: <MileageTableListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableForm/Add',
    },
    component: <MileageTableForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MileageTable/MileageTableForm/Edit',
    },
    component: <MileageTableForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimParameters/MealType/MealTypeListing',
    },
    component: <MealTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimParameters/Venue/VenueListing',
    },
    component: <VenueListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimType/ClaimTypeListing',
    },
    component: <ClaimTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyListing',
    },
    component: <ClaimPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyForm/Add',
    },
    component: <ClaimPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyForm/edit',
    },
    component: <ClaimPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDetail',
    },
    component: <ClaimPolicyDetail />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDailyForm/Add',
    },
    component: <ClaimPolicyDailyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyDailyForm/Edit',
    },
    component: <ClaimPolicyDailyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyMealForm/Add',
    },
    component: <ClaimPolicyMealForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ClaimPolicy/ClaimPolicyMealForm/Edit',
    },
    component: <ClaimPolicyMealForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicy',
    },
    component: <ClaimApprovalPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicyForm/add',
    },
    component: <ClaimApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ClaimApprovalPolicy/ClaimApprovalPolicyForm/edit',
    },
    component: <ClaimApprovalPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupListing',
    },
    component: <ShiftSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupForm/Add',
    },
    component: <ShiftSetupForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ShiftSetup/ShiftSetupForm/Edit/:shiftID',
    },
    component: <ShiftSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternListing',
    },
    component: <ShiftPatternListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternForm/Add',
    },
    component: <ShiftPatternForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ShiftPattern/ShiftPatternForm/Edit/:shiftID',
    },
    component: <ShiftPatternForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupListing',
    },
    component: <ShiftGroupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupForm/Add',
    },
    component: <ShiftGroupForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ShiftGroup/ShiftGroupForm/Edit',
    },
    component: <ShiftGroupForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyListing',
    },
    component: <OvertimePolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyForm/Add',
    },
    component: <OvertimePolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimePolicy/OvertimePolicyForm/Edit',
    },
    component: <OvertimePolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableListing',
    },
    component: <OvertimeClaimTableListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableForm/Add',
    },
    component: <OvertimeClaimTableForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableForm/Edit',
    },
    component: <OvertimeClaimTableForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeClaimTable/OvertimeClaimTableView',
    },
    component: <OvertimeClaimTableView mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeReasonCodes/OvertimeReasonListing',
    },
    component: <OvertimeReasonListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingListing',
    },
    component: <OvertimeRoundingListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingPolicyForm/add',
    },
    component: <OvertimeRoundingPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/OvertimeRoundingPolicy/OvertimeRoundingPolicyForm/edit/:OTRoundingID',
    },
    component: <OvertimeRoundingPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyListing',
    },
    component: <AllowanceDeductionRoundingPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm/Add',
    },
    component: <AllowanceDeductionRoundingPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm/Edit/:AllowanceDeductionID',
    },
    component: <AllowanceDeductionRoundingPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AllowanceDeductionRoundingPolicy/AllowanceDeductionRoundingPolicyForm/Edit/:AllowanceDeductionID',
    },
    component: <AllowanceDeductionRoundingPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyListing',
    },
    component: <DailyAllowancePolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyForm/Add',
    },
    component: <DailyAllowancePolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DailyAllowencePolicy/DailyAllowancePolicyForm/Edit/:dailyID',
    },
    component: <DailyAllowancePolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyListing',
    },
    component: <MonthlyAllowancePolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyForm/Add',
    },
    component: <MonthlyAllowancePolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MonthlyAllowancePolicy/MonthlyAllowancePolicyForm/Edit/:monthlyID',
    },
    component: <MonthlyAllowancePolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyListing',
    },
    component: <DeductionPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm/Add',
    },
    component: <DeductionPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm/Edit/:deductionID',
    },
    component: <DeductionPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/DeductionPolicy/DeductionPolicyForm/Edit/:deductionID',
    },
    component: <DeductionPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyListing',
    },
    component: <TimeOffPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyForm/Add',
    },
    component: <TimeOffPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TimeOffPolicy/TimeOffPolicyForm/Edit/:TimeOffSettingID',
    },
    component: <TimeOffPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TimeOffReason/TimeOffReasonListing',
    },
    component: <TimeOffReasonListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInListing',
    },
    component: <MobileCheckInListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceListing',
    },
    component: <BeaconDeviceListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceForm/Add',
    },
    component: <BeaconDeviceForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/BluetoothBeaconDevices/BeaconDeviceForm/Edit',
    },
    component: <BeaconDeviceForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm/Add',
    },
    component: <MobileCheckInForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm/View/:checkInID',
    },
    component: <GenerateQR />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/MobileCheckInLocation/MobileCheckInForm/Edit/:checkInID',
    },
    component: <MobileCheckInForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSAPMenu',
    },
    component: <TMSAPMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/overtime',
    },
    component: <TMSApprovalPolicy type="OverTime" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/timeoff',
    },
    component: <TMSApprovalPolicy type="TimeOff" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/changeshiftrequest',
    },
    component: <TMSApprovalPolicy type="ChangeShiftRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/AttendanceCorrectionRequest',
    },
    component: <TMSApprovalPolicy type="AttendanceCorrectionRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicy/batchovertimeentry',
    },
    component: <TMSApprovalPolicy type="BatchOvertimeEntry" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/overtime/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="OverTime" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/timeoff/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="TimeOff" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/changeshiftrequest/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="ChangeShiftRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/attendancecorrectionrequest/Add',
    },
    component: (
      <TMSApprovalPolicyForm mode="add" type="AttendanceCorrectionRequest" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/batchovertimeentry/Add',
    },
    component: <TMSApprovalPolicyForm mode="add" type="BatchOvertimeEntry" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/overtime/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="OverTime" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/timeoff/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="TimeOff" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/changeshiftrequest/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="ChangeShiftRequest" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/batchovertimeentry/Edit',
    },
    component: <TMSApprovalPolicyForm mode="edit" type="BatchOvertimeEntry" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/TMSApprovalPolicy/TMSApprovalPolicyForm/attendancecorrectionrequest/Edit',
    },
    component: (
      <TMSApprovalPolicyForm mode="edit" type="AttendanceCorrectionRequest" />
    ),
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy',
    },
    component: <CommonSettingRequisitionAPMenu />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitiontypes',
    },
    component: <CommonSettingRequisitionTypeListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/asset',
    },
    component: <RequisitionApprovalPolicyList type="Asset" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/cash',
    },
    component: <RequisitionApprovalPolicyList type="Cash" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/others',
    },
    component: <RequisitionApprovalPolicyList type="Others" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/travel',
    },
    component: <RequisitionApprovalPolicyList type="Travel" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/staffmovementrequest',
    },
    component: <RequisitionApprovalPolicyList type="Staff Movement" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/detail',
    },
    component: <RequisitionApprovalPolicyDetail />,
  },

  //Add

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/asset/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Asset" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/cash/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Cash" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/others/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Others" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/travel/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Travel" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/staffmovement/add',
    },
    component: <RequisitionApprovalPolicyForm mode="add" type="Staff Movement" />,
  },


  /// EDIT

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/asset/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Asset" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/cash/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Cash" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/others/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Others" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/Requisitionapprovalpolicy/travel/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Travel" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingcourseclassificationlisting',
    },
    component: <TrainingCourseClassificationListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingcourselisting',
    },
    component: <TrainingCoursesListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingcourselisting/allcourseslisting',
    },
    component: <AllCoursesListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/authorisedtrainingproviderslisting',
    },
    component: <AuthorisedTrainingProviders />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/authorisedtrainingproviderslisting/form',
    },
    component: <AuthorisedTrainingProvidersForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/authorisedtrainingproviderslisting/courseslisting',
    },
    component: <CoursesListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingcourselisting/allcourseslisting/edittrainingcourse',
    },
    component: <EditTrainingCourse />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingcourselisting/allcourseslisting/preferredtrainer',
    },
    component: <PreferredTrainer />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingcourselisting/allcourseslisting/jobgradeallowed',
    },
    component: <JobGradeAllowed />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingcostitemlisting',
    },
    component: <TrainingCostItemListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/TrainingManagement/trainingapprovalpolicy',
    },
    component: <TrainingApprovalPolicyForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/RequisitionManagement/requisitionapprovalpolicy/staffmovement/edit',
    },
    component: <RequisitionApprovalPolicyForm mode="edit" type="Staff Movement" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/ratingscalelisting',
    },
    component: <RatingScaleListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/ratingscalelisting/form',
    },
    component: <RatingScaleForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/finalratingtablelisting',
    },
    component: <FinalRatingTableListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/finalratingtablelisting/form',
    },
    component: <FinalRatingTableForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalpolicylisting/form',
    },
    component: <AppraisalPolicyForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalpolicylisting',
    },
    component: <AppraisalPolicyListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/jobskillcategorylisting',
    },
    component: <JobSkillCategoryListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/competencyitemslisting',
    },
    component: <CompetencyItemsListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/competencyitemslisting/competencyitemsdetails',
    },
    component: <CompetencyItemsDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/competencyitemslisting/competencyitemsdetails/competencyitemsform',
    },
    component: <CompetencyItemsForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/otherappraisalitemslisting',
    },
    component: <OtherAppraisalItemsListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/otherappraisalitemslisting/otherappraisalitemsform',
    },
    component: <OtherAppraisalItemsForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/kpiappraisaltemplatelisting',
    },
    component: <KpiAppraisalTemplateListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/kpiappraisaltemplatelisting/kpiappraisaltemplatedetails',
    },
    component: <KpiAppraisalTemplateDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/kpiappraisaltemplatelisting/kpiappraisaltemplatedetails/kpiappraisaltemplateforms',
    },
    component: <KpiAppraisalTemplateForms />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/skillsappraisaltemplatelisting',
    },
    component: <SkillsAppraisalTemplateListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/skillsappraisaltemplatelisting/skillsappraisaltemplatedetails',
    },
    component: <SkillsAppraisalTemplateDetails />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/skillsappraisaltemplatelisting/skillsappraisaltemplatedetails/skillsappraisaltemplateforms',
    },
    component: <SkillsAppraisalTemplateForms />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/othersappraisaltemplatelisting',
    },
    component: <OthersAppraisalTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/othersappraisaltemplatelisting/othersappraisaltemplatedetails',
    },
    component: <OthersAppraisalTemplateDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalschedulelisting',
    },
    component: <AppraisalScheduleListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalschedulelisting/appraisalscheduleform/:appraisalScheduleID',
    },
    component: <AppraisalScheduleForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisaltemplatelisting',
    },
    component: <AppraisalTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisaltemplatelisting/jobposition',
    },
    component: <AppraisalTemplateJobPosition />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisaltemplatelisting/jobposition/details',
    },
    component: <AppraisalTemplateJobPositionDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalapprovalprocesslisting',
    },
    component: <AppraisalApprovalProcessListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalapprovalprocesslisting/form',
    },
    component: <AppraisalApprovalProcessForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/scaleratinglisting',
    },
    component: <ScaleRatingListing />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/scaleratinglisting/scaleratingform',
    },
    component: <ScaleRatingForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalapprovalpolicy',
    },
    component: <AppraisalApprovalPolicyList />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalapprovalpolicy/add',
    },
    component: <AppraisalApprovalPolicyForm mode="add" />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/GeneralSettingListingV2/AppraisalManagement/appraisalapprovalpolicy/edit',
    },
    component: <AppraisalApprovalPolicyForm mode="edit" />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/companyinfoform',
    },
    component: <SystemAdminCompanyInfoForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/companyinfoview',
    },
    component: <CompanyInfoView />,
  },
    
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/EmployeeApp/employeeappseclist',
    },
    component: <EmployeeAppSecurityListing />,
  },
  
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/EmployeeApp/employeeappsecform',
    },
    component: <EmployeeAppSecurityForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/department',
    },
    component: <DepartmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/department/division',
    },
    component: <DivisionListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/reportingstructure',
    },
    component: <ReportingStructureGroup />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/reportingstructure/form',
    },
    component: <ReportingStructure />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/Company/CompanySummary/reportingstructure/form/jobposition',
    },
    component: <JobPositionListng />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/statutoryinfo',
    },
    component: <StatutoryInfo />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/generaldocument',
    },
    component: <CompanyGeneralDocument />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/glaccountmappinglisting',
    },
    component: <GLAccountMappingListing />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/ProjectMaster',
    },
    component: <ProjectMasterListing />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/ProjectExpenseType',
    },
    component: <ProjectExpenseTypeListing />,
  },
  {
    props: {
      exact: true,
      path: '/commonsetting/glaccountmappinglisting/form',
    },
    component: <GLAccountMappingForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/Company/CompanySummary/generaldocument/employeeattachment',
    },
    component: <CompanyEmployeeAttachment />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/rolelisting',
    },
    component: <RoleListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/roleassignmentlisting',
    },
    component: <RoleAssignmentListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/companyhierarchy',
    },
    component: <CompanyHierarchy />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/orgunitstructure',
    },
    component: <OrgUnitStructure />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/Company/CompanySummary/orgunitstructure/orgunitstructureform',
    },
    component: <OrgUnitStructureForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/jobpositionbyorgunit',
    },
    component: <JobPostionByOrgUnit />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/accesspermissionrolelist',
    },
    component: <AccessPermissionRoleListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/accesspermission',
    },
    component: <AccessPermission />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/autonumberingassignment',
    },
    component: <AutoNumberingAssignment />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/Company/CompanySummary/autonumberingassignment/form',
    },
    component: <AutoNumberingAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/listing',
    },
    component: <ProjectExpenseGLAccountMappingListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseGLAccountMapping/listing/form',
    },
    component: <ProjectExpenseGLAccountMappingForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicy',
    },
    component: <ProjectExpenseClaimApprovalPolicy />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicyForm/add',
    },
    component: <ProjectExpenseClaimApprovalPolicyForm mode="add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectExpenseClaimApprovalPolicy/ClaimApprovalPolicyForm/edit',
    },
    component: <ProjectExpenseClaimApprovalPolicyForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/FormItemRatingSetup/listing',
    },
    component: <FormItemRatingSetupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/FormItemRatingSetup/listing/form',
    },
    component: <FormItemRatingSetupForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewGradingTable/listing',
    },
    component: <ReviewGradingTableListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewGradingTable/listing/form',
    },
    component: <ReviewGradingTableForm />,
  },

  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupListing',
    },
    component: <ImportDataSetupListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MulticurrencySetup',
    },
    component: <MulticurrencySetupMenu />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/MulticurrencyListing',
    },
    component: <MulticurrencyListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MultiCurrencyImport',
    },
    component: <MultiCurrencyImport />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MultiCurrencyExcelValidation',
    },
    component: <MultiCurrencyExcelValidation />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/MulticurrencyForm',
    },
    component: <MulticurrencyForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupForm',
    },
    component: <ImportDataSetupForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/AutomatedClockDataImport/ImportDataSetupForm/edit',
    },
    component: <ImportDataSetupForm mode="edit" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyListing',
    },
    component: <PeriodicDeductionPolicyListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyForm/Add',
    },
    component: <PeriodicDeductionPolicyForm mode="Add" />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicDeductionPolicy/PeriodicDeductionPolicyForm/Edit/:PeriodicDeductionID',
    },
    component: <PeriodicDeductionPolicyForm mode="Edit" />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewPolicy/listing',
    },
    component: <ReviewPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewPolicy/listing/form',
    },
    component: <ReviewPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewGroupL/listing/',
    },
    component: <ReviewGroupListing />,
  },

  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpicategorylisting',
    },
    component: <KpiCategoryListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpiitemslisting',
    },
    component: <KpiItemsListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpiitemslisting/kpiitemsdetails',
    },
    component: <KpiItemsDetails />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/kpiitemslisting/kpiitemsdetails/kpiitemsform',
    },
    component: <KpiItemsForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpitemplatelisting',
    },
    component: <KPIPredefinedTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/kpitemplatelisting/kpitemplateform',
    },
    component: <KPIPredefinedTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/kpitemplatelisting/kpitemplateform/kpitemplateformdetails',
    },
    component: <KPIPredefinedTemplateDetailsForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/reviewitemslisting',
    },
    component: <ReviewItemsListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/reviewitemsform',
    },
    component: <ReviewItemsForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/reviewtemplatelisting',
    },
    component: <ReviewTemplateListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/reviewtemplatelisting/reviewtemplateform',
    },
    component: <ReviewTemplateForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/rewardgrouplisting',
    },
    component: <RewardGroupListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewProcessPolicy/listing',
    },
    component: <ReviewProcessPolicyListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewProcessPolicy/listing/form',
    },
    component: <ReviewProcessPolicyForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewFormTemplate/listing',
    },
    component: <ReviewFormTemplateListing />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/GeneralSetting/ReviewFormTemplate/listing/form',
    },
    component: <ReviewFormTemplateForm />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PersonnelEFileSecurityPolicy/PersonnelEFileSecurityPolicyForm',
    },
    component: <PersonnelEFileSecurityPolicyForm />,
  },
  //Project Timesheet
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectTimesheet/ApproverRecordListing',
    },
    component: <ApproverRecordListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectTimesheet/ApproverAssignmentListing',
    },
    component: <ApproverAssignmentListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/ProjectTimesheet/ApproverAssignmentForm',
    },
    component: <ApproverAssignmentForm />,
  },
  {
    props: {
      exact: true,
      path: '/SystemAdmin/ReporitngStructureGroup',
    },
    component: <ReportingStructureGroupListing />,
  },
  {
    props: {
      exact: true,
      path:
        '/SystemAdmin/GeneralSetting/PeriodicAllowanceType/PeriodicAllowanceTypeListing',
    },
    component: <PeriodicAllowanceTypeListing />,
  },










]
export default HomeRoutes
